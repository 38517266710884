import { queryBuilder } from '../../../common/fetch'
import { config } from '../../../common/utils'
import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import authorizedApiRequest from '../../../common/fetch/authorized'
import appendToFormData from '../../../common/fetch/appendToFormData'

import {
  updateDebt,
  getDebtsListSuccess,
  addDebtSuccess,
  removeDebtSuccess,
  createDebtPartSuccess,
  removeDebtPartSuccess,
} from '../../../actions/debts'

import { DEBTS } from '../../../actions/debts/types'
import { getClientsList } from '../../../selectors'

function* fetchUserDebtsList({ payload }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/debts${queryBuilder(payload)}`,
    {
      method: 'GET',
    },
  )

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield put(getDebtsListSuccess(serverResponse))
  }
}

function* addDebt({ payload: { editMode, formData, submitCallback } }) {
  const formattedFormData = {
    _method: editMode ? 'PUT' : undefined,
    ...formData,
  }

  const payloadResponse = yield call(authorizedApiRequest, '/debts', {
    method: 'POST',
    body: appendToFormData(new FormData(), formattedFormData),
  })

  yield call(submitCallback, payloadResponse)

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()
    const clientsList = yield select(getClientsList)

    yield put(
      addDebtSuccess({
        ...serverResponse,
        parts: [],
        client: clientsList[serverResponse.client_id],
      }),
    )
  }

  if (payloadResponse.status === 204) {
    yield put(updateDebt(formData))
  }
}

function* addDebtPart({ payload: { formData, submitCallback } }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/debts/${formData.debt_id}`,
    {
      method: 'POST',
      body: appendToFormData(new FormData(), formData),
    },
  )

  yield call(submitCallback, payloadResponse)

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield put(createDebtPartSuccess(serverResponse))
  }
}

function* removeDebt({ payload: debt_id }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/debts/${debt_id}`,
    {
      method: 'DELETE',
    },
  )

  if (payloadResponse.status === 200) {
    yield put(removeDebtSuccess(debt_id))
  }
}

function* removeDebtPart({ payload: { debtId, debtPartId } }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/debts/parts/${debtPartId}`,
    {
      method: 'DELETE',
    },
  )

  if (payloadResponse.status === 200) {
    yield put(removeDebtPartSuccess(debtId, debtPartId))
  }
}

export default function* clientsSagaWatcher() {
  yield all([
    takeEvery(DEBTS.REQUEST_ADD, addDebt),
    takeEvery(DEBTS.REQUEST_LIST, fetchUserDebtsList),
    takeEvery(DEBTS.REQUEST_REMOVE, removeDebt),
    takeEvery(DEBTS.REQUEST_ADD_PART, addDebtPart),
    takeEvery(DEBTS.REQUEST_REMOVE_PART, removeDebtPart),
  ])
}
