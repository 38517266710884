import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { clearAuthErrors, requestLogin } from '../../actions/auth'
import { useForm } from '../../common/hooks'
import { FormInput, Layout } from '../../common/components'
import {
  getAuthStatus,
  getLoginErrors,
  getLoginIsFetchingStatus,
} from '../../selectors'

const LoginPage = () => {
  const { values: formData, handleChange: setFormData } = useForm()
  const dispatch = useDispatch()

  const errors = useSelector(getLoginErrors)
  const isAuthentificated = useSelector(getAuthStatus)
  const loginIsFetching = useSelector(getLoginIsFetchingStatus)

  const handleSubmitForm = e => {
    e.preventDefault()

    dispatch(requestLogin(formData))
  }

  const errorMessages = Object.values(errors)

  useEffect(() => {
    return () => {
      dispatch(clearAuthErrors())
    }
  }, [])

  if (isAuthentificated) {
    return <Redirect to="/deshboard" />
  }

  return (
    <Layout>
      <form onSubmit={handleSubmitForm} className="inputForm">
        <FormInput
          label="Email"
          name="email"
          type="string"
          value={formData.email}
          onChange={setFormData}
        />
        <FormInput
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={setFormData}
        />
        {errorMessages.length ? (
          <div className="errorContainer">
            {errorMessages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </div>
        ) : null}
        <div className="formFooter">
          <button type="submit" className="button" disabled={loginIsFetching}>
            {(loginIsFetching && 'submit...') || 'submit'}
          </button>
        </div>
      </form>
    </Layout>
  )
}

export default LoginPage
