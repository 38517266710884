import { all, call, put, takeEvery } from 'redux-saga/effects'
import authorizedApiRequest from '../../../common/fetch/authorized'
import fetchPersonalData from './profile'
import logoutSaga from './logout'
import loginSaga, { signUpUser } from './login'

import {
  loginSucces,
  setProfileData,
  refreshTokenSucces,
  fetchInitialStateFail,
  fetchInitialStateSucces,
} from '../../../actions/auth'

import { AUTH_STATE, LOGIN, LOGOUT, SIGNUP } from '../../../actions/auth/types'

import { fetchDriversList } from '../drivers'
import { fetchClientsList } from '../clients'

export function* authSucces(status) {
  if (status === 'RESOLVED') {
    const userData = yield call(fetchPersonalData)

    yield put(setProfileData(userData))

    yield call(fetchDriversList)
    yield call(fetchClientsList)
  }

  yield put(fetchInitialStateSucces())
}

export function* refreshAccessToken() {
  const oldToken = localStorage.getItem('ACCESS_TOKEN')

  const payload = yield call(authorizedApiRequest, '/account/refresh', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${oldToken}`,
    },
  })

  if (payload.status === 200) {
    const { access_token, expires_in } = yield payload.json()

    const now = new Date()
    const expiresOn = now.setSeconds(now.getSeconds() + expires_in)

    localStorage.setItem('ACCESS_TOKEN', access_token)
    localStorage.setItem('ACCESS_TOKEN_EXPIRES_ON', expiresOn)

    const userData = yield call(authSucces, 'RESOLVED')

    yield put(refreshTokenSucces())
    yield put(setProfileData(userData))
  } else {
    localStorage.removeItem('ACCESS_TOKEN')
    localStorage.removeItem('ACCESS_TOKEN_EXPIRES_ON')

    yield put(fetchInitialStateFail())
  }
}

function* fetchAuthState() {
  const access_token = localStorage.getItem('ACCESS_TOKEN')
  const expires_in = localStorage.getItem('ACCESS_TOKEN_EXPIRES_ON')

  if (access_token && expires_in) {
    if (parseInt(expires_in, 10) > new Date().getTime()) {
      yield put(loginSucces())
      yield call(authSucces, 'RESOLVED')
    } else {
      try {
        yield call(refreshAccessToken)
      } catch (e) {
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem('ACCESS_TOKEN_EXPIRES_ON')

        yield put(fetchInitialStateFail())
      }
    }
  }

  yield call(authSucces, 'REJECTED')
}

export default function* authSagaWatcher() {
  yield all([
    takeEvery(AUTH_STATE.REQUEST, fetchAuthState),
    takeEvery(LOGIN.REQUEST, loginSaga),
    takeEvery(SIGNUP.REQUEST, signUpUser),
    takeEvery(LOGOUT.REQUEST, logoutSaga),
  ])
}
